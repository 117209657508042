//#region API's
export const ML_API = "/api/ml/";
export const EXT_API = "/api/ext/";
export const REG_API = "/api/reg/";
export const NRJ_API = "/api/nrj/";
export const EDL_API = "/api/edl/";
export const API_URL = '/api/test/';
export const RENT_API = "/api/rent";
export const HTML_API = "/api/html/";
export const PRED_API = "/api/pred/";
export const FILE_API = "/api/file/";
export const LINK_API = "/api/link/";
export const TREE_API = "/api/tree/";
export const DATA_API = "/api/data/";
export const LANG_API = "/api/lang/";
export const FORM_API = "/api/forms/";
export const ADMIN_API = "/api/admin/";
export const EXCEL_API = "/api/excel/";
export const EDITS_API = "/api/edits/";
export const GRAPH_API = "/api/graphs/";
export const IMPORT_API = "/api/import";
export const API_COORDS = "/api/coords/";
export const AUTH_API_URL = "/api/auth/";
export const REPORT_URL = "/api/report/";
export const PARITY_API = "/api/parity/";
export const ACTION_API = "/api/action/";
export const MATRIX_API = "/api/matrix/";
export const TABLES_API = "/api/tables/";
export const NAV_API = "/api/navigator/";
export const OFFLINE_API = "/api/offline/";
export const MISSION_API = "/api/mission/";
export const DATASETS_API = "/api/datasets/";
export const DASHBOARD_API = "/api/dashboard/";
export const ITEM_ORDER_API = "/api/item_order/";
export const MAINTENANCE_API = "/api/maintenance/";
//#endregion

//#region Socket
export const DIA_SOCKET = "/api/socket/dia/";
export const FORM_SOCKET = "/api/socket/form/";
export const GLOBAL_SOCKET = "/api/socket/global/";
//#endregion

//#region DO NOT UPDATE THESE WHEN MAJ
export const GRAFANA_REF = "gmao";
export const APP_IP_SHORT = "172.16.121.95";
export const APP_DOMAIN = "https://app.aiset.io";
export const SUPERSET_IP = "http://172.16.120.186";
export const ADMIN_BOT_ID = "61a5ec3b38ccdf0846fcadc0";
export const AGILYTICS_IP = "http://172.16.122.222:5001";
export const SUPERSET_RE_ROUTED = "https://superset.aiset.io";
export const IP_DIGITS = APP_IP_SHORT.split('.').slice(-1)[0];
export const SUPERSET_SESSION_IP = "http://192.168.100.137:8810";
//#endregion

export const QR_URL = APP_DOMAIN + "/QR/";
export const DEFAULT_USER_MAIL = "default@fando.be";
export const MAIL_ADMIN_AUTO_TICKET_CREATOR = "info@aiset.io";
export const CRAFT_FILE_URL_NO_DOMAIN = (_id, name) => `${FILE_API}get/${_id}/${name}`;
export const CRAFT_FILE_URL_BACKEND = (_id, name) => `${APP_DOMAIN}${FILE_API}get/${_id}/${name}`;
export const CRAFT_FILE_URL = (_id, name) => `${window?.location?.origin}${FILE_API}get/${_id}/${name}`;

export const ALL_IPS = [
  { domain: "https://app.aiset.io", ip: "http://172.16.121.95:3000" },
  { domain: "https://app.fando.be", ip: "http://172.16.121.95:3000" },
  { domain: "https://dev.aiset.io", ip: "http://172.16.121.75:3000" },
  { domain: "https://devgmao.fando.be", ip: "http://172.16.121.85:3000" },
]