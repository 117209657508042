import React from "react";
import * as G from "../Grid";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { CellsTypes as CT } from "../AgGridDefs";
import { FP, T, TC, TABS, GAMMES } from "../../../Constants";

type Row = ReturnType<T.API.Utils.Tables.GetEquipGammesRows>[number];
type Column = ReturnType<T.API.Utils.Tables.GetEquipGammesColumns>[number];

export type GammesProps = {
    /** The table id */
    origin: string;
    /** Do not create Sub-Columns for the properties loaded */
    no_sub_columns_for_loaded?: boolean;
};

export const Gammes: React.FC<GammesProps> = props => {
    const lg = H.useLanguage();
    const [forms] = H.useFormIds();
    const [{ isAdmin }] = H.useAuth();
    const loading = H.useBoolean(false);
    const [rows, set_rows, row_status] = H.useAsyncState<Row[]>([]);
    const form_id = React.useMemo(() => forms[FP.EQUIPEMENT_FORM], [forms]);
    const [columns, set_columns, col_status] = H.useAsyncState<Column[]>([]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getEquipGammesColumns()
            .then(({ data }) => isSubscribed && set_columns(data, "done"))
            .catch(() => isSubscribed && set_columns([], "error"));
        return () => {
            isSubscribed = false;
            set_columns([], "load");
        }
    }, [set_columns]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getEquipGammesRows()
            .then(({ data }) => isSubscribed && set_rows(data, "done"))
            .catch(() => isSubscribed && set_rows([], "error"));
        return () => {
            isSubscribed = false;
            set_rows([], "load");
        }
    }, [set_rows]);

    const tr_rows = React.useMemo(() => rows.map(r => {
        let all_levels = [
            { id: r.id_level_7, name: r.name_level_7 },
            { id: r.id_level_6, name: r.name_level_6 },
            { id: r.id_level_5, name: r.name_level_5 },
            { id: r.id_level_4, name: r.name_level_4 },
            { id: r.id_level_3, name: r.name_level_3 },
            { id: r.id_level_2, name: r.name_level_2 },
            { id: r.id_level_1, name: r.name_level_1 },
        ];
        let last_level = all_levels.find(l => l.id);

        return {
            ...r,
            last_level: lg.getTextObj(last_level.id, "name", last_level.name),
            tr_name_level_1: lg.getTextObj(r.id_level_1, "name", r.name_level_1),
            tr_name_level_2: lg.getTextObj(r.id_level_2, "name", r.name_level_2),
            tr_name_level_3: lg.getTextObj(r.id_level_3, "name", r.name_level_3),
            tr_name_level_4: lg.getTextObj(r.id_level_4, "name", r.name_level_4),
            tr_name_level_5: lg.getTextObj(r.id_level_5, "name", r.name_level_5),
            tr_name_level_6: lg.getTextObj(r.id_level_6, "name", r.name_level_6),
            tr_name_level_7: lg.getTextObj(r.id_level_7, "name", r.name_level_7),
            tr_red_flag: lg.getStaticText(GAMMES.RED_FLAG_CAT.find(c => c.value === r.red_flag)?.label),
            tr_active: (r.active || []).map(a => lg.getStaticText(GAMMES.ACTIVE.find(c => c.value === a)?.label)).join(),
        }
    }), [rows, lg]);

    const always_allow_edit_fields = React.useMemo<(keyof typeof tr_rows[number])[]>(() => [
        "isEquipment", "definition", "gamme_lifespan", "tr_active", "tr_red_flag", "equip_color",
    ], []);

    const cols = React.useMemo(() => {
        let fixed_columns: G.TableProps<Row>["columns"] = [
            { field: "last_level", headerName: FP.EQUIPMENT_GAMME, editable: false },
            { field: "tr_name_level_1", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 1 } },
            { field: "tr_name_level_2", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 2 } },
            { field: "tr_name_level_3", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 3 } },
            { field: "tr_name_level_4", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 4 } },
            { field: "tr_name_level_5", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 5 } },
            { field: "tr_name_level_6", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 6 } },
            { field: "tr_name_level_7", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 7 } },
            { field: "omniclass", headerName: TC.EQUIP_STORE_OMNICLASS, editable: false },
            { field: "isEquipment", headerName: TC.GAMME_TABLE_IS_EQUIPMENT, type: CT.TYPE_CHECKBOX },
            { field: "definition", headerName: TC.GAMME_TABLE_DEFINITION },
            { field: "gamme_lifespan", headerName: TC.GAMME_TABLE_LIFESPAN, type: CT.TYPE_NUMBER },
            { field: "tr_active", headerName: TC.ALARM_ACTIVE, type: CT.TYPE_SELECT, params: { values: GAMMES.ACTIVE, field_value: "active", multiple: true }, },
            { field: "tr_red_flag", headerName: TC.CATEGORIES_RED_FLAG, type: CT.TYPE_SELECT, params: { values: GAMMES.RED_FLAG_CAT, field_value: "red_flag", empty_option: true } },
            { field: "equip_color", headerName: TC.GLOBAL_COLOR, type: CT.TYPE_COLOR },
            { field: "brand", headerName: "brand", hide: true, type: CT.TYPE_CHECKBOX, params: { header_id: form_id, distinctFalseFromEmpty: true } },
            { field: "model", headerName: "model", hide: true, type: CT.TYPE_CHECKBOX, params: { header_id: form_id, distinctFalseFromEmpty: true } },
        ];

        const recursive = (element: Column, parent = fixed_columns) => {
            if (element.col_type === "group") {
                if (props.no_sub_columns_for_loaded) {
                    for (let sub_elem of element.content) recursive(sub_elem);
                }
                else {
                    let name = "";
                    if (Array.isArray(element.labels)) name = element.labels.map(l => lg.getStaticText(l)).join(" - ");
                    else name = lg.getStaticText(element.label)

                    let new_col = { children: [], headerName: name } as typeof parent[number];
                    for (let sub_elem of element.content) recursive(sub_elem, (new_col as any).children);
                    parent.push(new_col);
                }
            }
            else {
                let editable = true,
                    field = element.prop,
                    type = CT.TYPE_CHECKBOX,
                    params = { distinctFalseFromEmpty: true } as G.ColDefParams<Row>;

                if (field !== "tags") {
                    let name = lg.getTextObj(form_id, element.prop, element.prop);
                    if (element.extra?.unit) name += " [" + lg.getStaticText(element.extra.unit) + "]";
                    parent.push({ field, headerName: name, type, hide: true, editable, params: { ...params } });
                }
            }
        }

        for (let elem of columns) recursive(elem);
        return fixed_columns;
    }, [columns, form_id, lg, props.no_sub_columns_for_loaded]);

    const check_edit_possible = React.useCallback<G.TableProps<Row>["onCellEditingStarted"]>(event => {
        let row = event.data;
        let field = event.colDef.field as keyof typeof tr_rows[number];
        // Trying to edit a name field
        if (field.startsWith("tr_name_level_")) event.api.stopEditing(true);
        // Trying to edit a property that can only be edit if the element is an equipment
        else if (!always_allow_edit_fields.includes(field) && !row.isEquipment) {
            event.api.stopEditing(true);
            M.renderAlert({ type: "warning", message: TC.GAMME_TABLE_EDIT_ONLY_EQUIP });
        }
    }, [always_allow_edit_fields]);

    const onValueChange = React.useCallback<G.TableProps<Row>["onValueChange"]>(event => {
        let row = event.data,
            old_value = event.oldValue,
            field = event.colDef.field as keyof typeof tr_rows[number];
        // Update the gamme's attribute
        if (always_allow_edit_fields.includes(field)) {
            // Update the 'active' property, and apply it to the children
            if (field === "tr_active") {

                const warning_promise = new Promise<boolean>(resolve => {
                    let new_active = event.newValue || [];
                    if (new_active.length > 0) resolve(true);
                    else M.askConfirm({ title: TC.GAMME_DEACTIVATION_WARNING, text: TC.GAMME_DEACTIVATION_WARNING_TEXT }).then(resolve);
                });

                warning_promise.then(confirmed => {
                    if (confirmed) {
                        loading.setTrue();
                        S.toggleEquipGamme({ gamme: event.node.data._id, active: event.newValue || [] })
                            .then(({ data }) => set_rows(p => p.map(r => data[r._id] ? { ...r, active: data[r._id] } : r)))
                            .catch(M.Alerts.updateError)
                            .catch(loading.setFalse);
                    }
                });
            }
            // Update another property, only on this gamme
            else {
                let sub_field: keyof T.EquipGammeData = null;
                if (field === "tr_red_flag") {
                    old_value = row.red_flag;
                    sub_field = "red_flag";
                }
                else sub_field = field as any;
                loading.setTrue();

                S.edit_gamme_field({ _id: row._id, field: sub_field, old_value, new_value: event.newValue }).then(({ data }) => {
                    if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                        if (confirmed) S.edit_gamme_field({ _id: row._id, field: sub_field, old_value: old_value, new_value: event.newValue, force_update: true }).then(({ data }) => {
                            if (data === "changed") M.Alerts.updateError();
                            else set_rows(p => p.map(r => r._id === row._id ? { ...r, [sub_field]: event.newValue } : r));
                            loading.setFalse();
                        }).catch(e => {
                            loading.setFalse();
                            M.Alerts.updateError(e);
                        });
                    });
                    else {
                        loading.setFalse();
                        set_rows(p => p.map(r => r._id === row._id ? { ...r, [sub_field]: event.newValue } : r));
                    }
                }).catch(e => {
                    loading.setFalse();
                    M.Alerts.updateError(e);
                });
            }
        }
        // Toggle a property for the gamme and it's descendants
        else if (!field.startsWith("tr_name_level_") && row[field] !== event.newValue) {
            // Update the data in the database, then update the state with the new data
            S.toggleEquipGammeProperty({ _id: row._id, field: field as any, activated: event.newValue })
                .then(({ data }) => set_rows(p => p.map(r => data.includes(r._id) ? { ...r, [field]: event.newValue } : r)))
                .catch(M.Alerts.updateError)
        }
    }, [always_allow_edit_fields, loading, set_rows]);

    const extra_buttons = React.useMemo<G.TableProps<Row>["extra_buttons"]>(() => ({
        hidden: () => !isAdmin,
        label: lg.getStaticText(TC.GAMME_TABLE_FORCE_INHERITANCE),
        onClick: () => {
            loading.setTrue();
            S.updateGammesInheritance()
                .then(({ data }) => set_rows(data))
                .catch(M.Alerts.updateError)
                .finally(loading.setFalse);
        },
    }), [set_rows, lg, isAdmin, loading]);

    return <div className="w-100">
        <C.Spinner error={row_status === "error" || col_status === "error"}>
            <G.Table<Row>
                sideBar
                rows={tr_rows}
                columns={cols}
                columns_base="all"
                extra_buttons={extra_buttons}
                onValueChange={onValueChange}
                onCellEditingStarted={check_edit_possible}
                adaptableId={props.origin || TABS.EQUIPMENTS_GAMMES_TABLE}
                loading={row_status === "load" || col_status === "load" || loading.value}
            />
        </C.Spinner>
    </div>;
};

export const GammesContext: React.FC = () => {
    H.useAuth({ tabName: TABS.EQUIPMENTS_GAMMES_TABLE });
    return <Gammes origin={TABS.EQUIPMENTS_GAMMES_TABLE} />;
}