import React from "react";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { T, TABS, TC, URL } from "../../Constants";
import { embedDashboard } from '@superset-ui/embedded-sdk';

type Panels = "elec" | "co2" | "water" | "waste";
type PanelsOptions = T.Option<Record<"container", React.RefObject<HTMLDivElement>>, Panels>;

const Dashboard_Ids: Record<Panels, string> = {
    co2: "8ccffa7e-b119-4a4f-87fa-7dd0c1770e10",
    elec: "d5a69b9f-d187-4e6c-8e45-dc5ec72a3724",
    waste: "8333b4fc-728a-408a-ad83-4bddb0353e9f",
    water: "bc832402-3ae4-4f77-a62d-31beed9aa62b",
}

const Environment: React.FC = () => {
    const lg = H.useLanguage();
    const [roots] = H.useRoots();
    H.useCrumbs(TC.TAB_ENV_DASHBOARD);
    const show_config = H.useBoolean(false);
    H.useAuth({ tabName: TABS.ENV_DASHBOARD });
    const [token, set_token, status] = H.useAsyncState("");
    const co2_container = React.useRef<HTMLDivElement>(null);
    const elec_container = React.useRef<HTMLDivElement>(null);
    const water_container = React.useRef<HTMLDivElement>(null);
    const waste_container = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        let isSubscribed = true;
        let dashboard_ids = Object.values(Dashboard_Ids);

        S.getSupersetToken({ context: roots, dashboard_ids })
            .then(({ data }) => isSubscribed && set_token(data.token, "done"))
            .catch(() => isSubscribed && set_token("", "error"));
        return () => {
            isSubscribed = false;
            set_token("", "error");
        }
    }, [set_token, roots]);

    const panels = React.useMemo<PanelsOptions[]>(() => [
        { container: elec_container, value: "elec", label: TC.SUPERSET_DASH_ELEC_TITLE },
        { container: co2_container, value: "co2", label: TC.SUPERSET_DASH_CO2_TITLE },
        { container: water_container, value: "water", label: TC.SUPERSET_DASH_WATER_TITLE },
        { container: waste_container, value: "waste", label: TC.SUPERSET_DASH_WASTE_TITLE },
    ], []);

    React.useEffect(() => {
        if (token) panels.forEach(panel => {
            embedDashboard({
                id: Dashboard_Ids[panel.value],
                mountPoint: panel.container.current,
                supersetDomain: URL.SUPERSET_RE_ROUTED,
                fetchGuestToken: () => new Promise(r => r(token)),
                dashboardUiConfig: {
                    hideTitle: true,
                    hideChartControls: false,
                    filters: { visible: false, expanded: false },
                    urlParams: { standalone: 3, show_filters: 0 },
                },
            })

            let iframe = panel.container.current.querySelector('iframe');
            if (iframe) {
                iframe.style.width = '100%';
                iframe.style.height = "955px";
            }
        });
    }, [token, panels]);

    return <>
        <C.Flex className="flex-grow-1" direction="column">
            {/* A button to open the configuration panel */}
            <C.Flex justifyContent="start" className="mb-2">
                <div>
                    {lg.getStaticText(TC.SUPERSET_DASH_JUMP_TO)} :
                    {panels.map(panel => <C.Button
                        size="sm"
                        variant="link"
                        className="mx-1"
                        key={panel.value}
                        text={panel.label}
                        onClick={() => panel.container.current?.scrollIntoView?.({ behavior: "smooth" })}
                    />)}
                </div>

                {/* <C.Button icon="cog" size="sm" text={TC.SUPERSET_DASH_CONFIG_BUTTON} onClick={show_config.setTrue} /> */}
            </C.Flex>

            {panels.map(panel => <div>
                <C.Title text={panel.label} level={4} />
                <div ref={panel.container} />
            </div>)}
        </C.Flex>

        {/* <BS.Offcanvas className="w-75" show={show_config.value} onHide={show_config.setFalse}>
            <BS.Offcanvas.Header closeButton>
                <BS.Offcanvas.Title>{TC.SUPERSET_DASH_CONFIG_TITLE}</BS.Offcanvas.Title>
            </BS.Offcanvas.Header>
            <BS.Offcanvas.Body>
                <div>
                    <C.Title text="todo energy & co2" level={5} />
                    <C.Title text="todo initial filter" level={6} />
                    <C.Title text="todo segmentation" level={6} />
                </div>
                <div>
                    <C.Title text="todo water" level={5} />
                    <C.Title text="todo initial filter" level={6} />
                    <C.Title text="todo segmentation" level={6} />
                </div>
                <div>
                    <C.Title text="todo waste" level={5} />
                    <C.Title text="todo initial filter" level={6} />
                    <C.Title text="todo segmentation" level={6} />
                </div>
            </BS.Offcanvas.Body>
        </BS.Offcanvas> */}

    </>;
};

export default Environment;