import axios from "axios";
import * as H from "../Headers";
import { T, TB, URL } from "../../Constants";

const { AUTH_API_URL } = URL;

type Login = T.API.ToAxios<T.API.Access.Auth.Login>;
type GetVersion = T.API.ToAxios<T.API.Access.Auth.GetVersion>;
type CleanCache = T.API.ToAxios<T.API.Access.Auth.CleanCache>;
type UserExists = T.API.ToAxios<T.API.Access.Auth.UserExists>;
type GetNavMenu = T.API.ToAxios<T.API.Access.Auth.GetNavMenu>;
type AdminCheck = T.API.ToAxios<T.API.Access.Auth.AdminCheck>;
type AGGridToken = T.API.ToAxios<T.API.Access.Auth.AGGridToken>;
type ArchiveUser = T.API.ToAxios<T.API.Access.Auth.ArchiveUser>;
type DeleteAlert = T.API.ToAxios<T.API.Access.User.DeleteAlert>;
type CheckVersion = T.API.ToAxios<T.API.Access.Auth.CheckVersion>;
type GetAlertsList = T.API.ToAxios<T.API.Access.User.GetAlertsList>;
type ResetPassword = T.API.ToAxios<T.API.Access.Auth.ResetPassword>;
type GetInfoBubble = T.API.ToAxios<T.API.Access.User.GetInfoBubble>;
type ContactSupport = T.API.ToAxios<T.API.Access.User.ContactSupport>;
type ForgotPassword = T.API.ToAxios<T.API.Access.Auth.ForgotPassword>;
type DefaultContext = T.API.ToAxios<T.API.Access.Auth.DefaultContext>;
type SaveInfoBubble = T.API.ToAxios<T.API.Access.User.SaveInfoBubble>;
type CreateShellUser = T.API.ToAxios<T.API.Access.Auth.CreateShellUser>;
type MarkAlertAsRead = T.API.ToAxios<T.API.Access.User.MarkAlertAsRead>;
type ConfirmUserOrder = T.API.ToAxios<T.API.Access.Auth.ConfirmUserOrder>;
type GetSupersetToken = T.API.ToAxios<T.API.Access.Auth.GetSupersetToken>;
type GetUserInfosBubble = T.API.ToAxios<T.API.Access.User.GetUserInfosBubble>;
type EditAnnouncementField = T.API.ToAxios<T.API.Access.User.EditAnnouncementField>;

type GetAllUserInfos = { isAdmin: boolean, portfolios: T.Portfolio[], user: T.Submission<T.UserData> };
type GetUserContextOpt = { links: T.Link[], sites: T.SiteType[], clients: T.ClientType[], buildings: T.BuildingType[] };

export const toggleUserMenuView = () => axios.get<boolean>(AUTH_API_URL + "toggleMenuView", H.getHeader("EDIT"));
export const getAllUserInfos = (id: string) => axios.post<GetAllUserInfos>(AUTH_API_URL + "load_all", { id }, H.getHeader());
export const getPortfolioUser = (user: string) => axios.post<T.Portfolio[]>(AUTH_API_URL + "portfolios", { user }, H.getHeader());
export const getUserContextOpt = (id: string) => axios.post<GetUserContextOpt>(AUTH_API_URL + "context_opt", { id }, H.getHeader());

/** Check if the user is an admin */
export const adminCheck: AdminCheck = () => axios.get(AUTH_API_URL + "admin/check");
/** Get the currently logged user's default context */
export const defaultContext: DefaultContext = () => axios.get(AUTH_API_URL + "default/context");
/** Log into the application */
export const login: Login = params => axios.post(AUTH_API_URL + "login", params, H.getHeader("LOGIN"));
/** Generate the navigation menu */
export const getNavMenu: GetNavMenu = context => axios.get(AUTH_API_URL + "navMenu", { params: { ...TB.toStringContext(context), user: H.get_user_id(), mission: H.get_mission_id() } });
/** Send an idea or a bug to the support team */
export const contact_support: ContactSupport = params => axios.post(AUTH_API_URL + "contact/support", params, H.getHeader("OTHER"));
/** Get a token containing AG Grid's API key */
export const agGridToken: AGGridToken = () => axios.get(AUTH_API_URL + "aggrid");
/** Check if a mail is already used */
export const mailExists: UserExists = params => axios.post(AUTH_API_URL + "mail/exists", params);
/** Create a user, without password, so can't log in */
export const createShellUser: CreateShellUser = params => axios.post(AUTH_API_URL + "create/user", params, H.getHeader("LOGIN"));
/** Ask another user for confirmation of subordination */
export const confirmUserOrder: ConfirmUserOrder = id => axios.post(AUTH_API_URL + "attach/user", { id }, H.getHeader("LOGIN"));
/** Change a user's password */
export const reset_password: ResetPassword = () => axios.get(AUTH_API_URL + "password");
/** Change an un-logged user's forgotten password */
export const forgot_password: ForgotPassword = mail => axios.post(AUTH_API_URL + "forgot/password", { mail });
/** 'Archive' a user's account, by removing it's roles and it's password. */
export const archiveUser: ArchiveUser = user => axios.post(AUTH_API_URL + "remove/user", { user }, H.getHeader("DELETE"));
/** Save a new InfoBubble Alert element */
export const saveInfoBubble: SaveInfoBubble = params => axios.post(AUTH_API_URL + "save/bubble", params, H.getHeader("EDIT"));
/** Retrieve infos about an info bubble, to be edited in a form */
export const getInfoBubble: GetInfoBubble = id => axios.post(AUTH_API_URL + "get/bubble", { id });
/** Get a list of alerts the user hasn't seen yet */
export const getUserInfosBubble: GetUserInfosBubble = () => axios.get(AUTH_API_URL + "user/bubble");
/** Mark an alert to not be showed again to the current user */
export const markAlertAsRead: MarkAlertAsRead = id => axios.post(AUTH_API_URL + "read/bubble", { id }, H.getHeader("EDIT"));
/** Get the list of the alerts currently in the database */
export const getAlertsList: GetAlertsList = ids => axios.post(AUTH_API_URL + "list/bubbles", { ids });
/** Edit an announcement value from the announcement table */
export const edit_announcement_field: EditAnnouncementField = params => axios.post(AUTH_API_URL + "edit/bubble", params, H.getHeader("EDIT"));
/** Delete an alert */
export const deleteAlert: DeleteAlert = ids => axios.post(AUTH_API_URL + "delete/bubble", { ids }, H.getHeader("DELETE"));
/** Force a cleaning of the cache */
export const cleanCache: CleanCache = () => axios.get(AUTH_API_URL + "clean_cache");
/** Check if the frontend version is the latest */
export const checkVersion: CheckVersion = () => axios.get(AUTH_API_URL + "check_version", { headers: { version: sessionStorage.getItem("app_release") } });
/** Get the current version of the app */
export const getVersion: GetVersion = () => axios.get(AUTH_API_URL + "version");
/** Generate a Superset token */
export const getSupersetToken: GetSupersetToken = params => axios.post(AUTH_API_URL + "superset/token", params);